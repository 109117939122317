import { FunctionComponent, useState, useEffect, ChangeEvent, useRef } from "react";
import { Navigate, Params, useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { boxShadows, containers, colours } from "../assets/css/variables";
import { useReactToPrint } from "react-to-print";
import { transitions } from "../assets/css/variables";

import { useApi } from '../context/ApiProvider';
import { SparrowHubApiInterface, QueueElement, UpdateQueueElementRequest, ProductRequest, ProcessNotificationNewRequest, QueueItem, QueueItemTypes, QueueStatuses, NotificationTypes, ErrorCode, CreateQueueElementRequest, CreateQueueNumberRequest, QueueTypes, GetCurrentPartnerResponse } from 'sparrowhub-client-axios';
import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { getCurrentTimestamp, searchMkRecursive, ticketContainsConsults, ticketContainsScripts } from '../helpers/utils';

import { IUser } from "../types/IUsers";
import { OrderDeliveryType } from "../types/IOrders";
import { ILocation, ILocations } from "../types/ILocations";
import { IScriptQueueConfig } from "../types/IScriptQueueConfig";

import { Body } from "../components/Body/Body";
import { ProcessSteps } from "../components/ProcessSteps/ProcessSteps";
import { Button, ButtonColour, ButtonIcon, ButtonType } from "../components/Button/Button";
import { InputField } from "../components/InputField/InputField";
import { QuantitySelector } from "../components/QuantitySelector/QuantitySelector";
import { Alert, AlertIcon, AlertType } from "../components/Alert/Alert";
import { Modal } from "../components/Modal/Modal";
import { ScriptDocket, ScriptDocketType } from "../components/ScriptDocket/ScriptDocket";
import { KeypadInput } from "../components/KeypadInput/KeypadInput";
import { ScriptInformation } from "../components/ScriptInformation/ScriptInformation";
import { Slider } from "../components/Slider/Slider";
import { TextArea } from "../components/TextArea/TextArea";
import { SelectInput, SelectInputOption } from "../components/SelectInput/SelectInput";
import { ScriptDetails } from "../components/ScriptDetails/ScriptDetails";
import { Checkbox } from "../components/Checkbox/Checkbox";
import { DefaultErrorMessage } from "../components/DefaultErrorMessage/DefaultErrorMessage";

import phoneIcon from '../assets/images/icons/Phone.svg';
import personIcon from '../assets/images/icons/Person.svg';
import plusIcon from '../assets/images/icons/Plus.svg';
import exclamationRedIcon from '../assets/images/icons/AlertRed.svg';
import arrowIcon from '../assets/images/icons/BackArrow.svg';
import paperScriptIcon from '../assets/images/icons/Prescriptions.svg';
import erxScriptIcon from '../assets/images/icons/Erx.svg';
import eScriptIcon from '../assets/images/icons/Escript.svg';
import sparrowhubLoader from "../assets/images/graphics/sparrowhub-loader.gif"
import deleteIcon from '../assets/images/icons/TrashNew.svg';
import syringeIcon from '../assets/images/icons/Syringe.svg';
import consultIcon from '../assets/images/icons/Consult.svg';


export enum ScriptProcess {
  CreateTicket = 'create-ticket',
  CollectTicket = 'collect-ticket'
}

export enum ScriptProcessStep {
  CustomerInformation = "Customer Info",
  TicketItems = "Ticket Items",
  Confirmation = "Confirmation",
  ScriptCollection = "Script Collection",
}

export enum QueueItemTypesGroup {
  Script = "script",
  Consultation = "consultation",
}

export const scriptProcessSteps = {
  'create-ticket': [
    ScriptProcessStep.TicketItems,
    ScriptProcessStep.CustomerInformation,
    ScriptProcessStep.Confirmation,
  ],
  'collect-ticket': [
    ScriptProcessStep.ScriptCollection,
  ]
}

const confirmCounsellingOptions: Array<SelectInputOption> = [
  {
    value: 'yes',
    label: 'Yes, the customer has received counselling'
  },
  {
    value: 'no',
    label: 'No, the customer has not received counselling'
  },
]

const confirmConsultationOptions: Array<SelectInputOption> = [
  {
    value: 'yes',
    label: 'Yes, the customer has received a consultation'
  },
  {
    value: 'no',
    label: 'No, the customer has not received a consultation'
  },
]

const confirmIdOptions: Array<SelectInputOption> = [
  {
    value: 'no-id-required',
    label: 'No ID check required'
  },
  {
    value: 'script-docket',
    label: 'Prescription Docket'
  },
  {
    value: 'drivers-license',
    label: 'Driver\'s License'
  },
  {
    value: 'student-id',
    label: 'Student ID'
  },
  {
    value: 'nsw-photo-card',
    label: 'NSW Photo Card'
  },
  {
    value: 'passport',
    label: 'Passport'
  },
  {
    value: 'seniors-card',
    label: 'Seniors Card'
  },
  {
    value: 'custom',
    label: 'Other'
  }
]

export const queueItemOptions: Array<any> = [
  {
    label: 'Paper Script',
    code: QueueItemTypes.PaperScript,
    group: QueueItemTypesGroup.Script,
    icon: paperScriptIcon
  },
  {
    label: 'eRx Paper Script',
    code: QueueItemTypes.ErxPaperScript,
    group: QueueItemTypesGroup.Script,
    icon: erxScriptIcon
  },
  {
    label: 'E-Script',
    code: QueueItemTypes.Escript,
    group: QueueItemTypesGroup.Script,
    icon: eScriptIcon
  },
  {
    label: 'Consultation',
    code: QueueItemTypes.GeneralConsult,
    group: QueueItemTypesGroup.Consultation,
    icon: consultIcon
  },
  {
    label: 'Vaccination',
    code: QueueItemTypes.Vaccination,
    group: QueueItemTypesGroup.Consultation,
    icon: syringeIcon
  },
  {
    label: 'Medical Certificate',
    code: QueueItemTypes.MedicalCertificate,
    group: QueueItemTypesGroup.Consultation,
    icon: consultIcon
  },
  {
    label: 'Scope Of Practice',
    code: QueueItemTypes.ScopeOfPractice,
    group: QueueItemTypesGroup.Consultation,
    icon: consultIcon
  }
]


type ScriptProcessPageProps = {
  loggedin: boolean
  user: IUser | null
  location: ILocation | null
  partner: GetCurrentPartnerResponse
  onSelectLocation: Function
  onSelectUser: Function
  onLogout: Function
  orders: Array<QueueElement>
  partnerLocations: ILocations
  setOrders: Function
  config: IScriptQueueConfig
}

export const ScriptProcessPage: FunctionComponent<ScriptProcessPageProps> = ({ loggedin, user, location, partner, onSelectLocation, onSelectUser, onLogout, orders, partnerLocations, setOrders, config }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const navigate = useNavigate();
  const docketsPrintRef = useRef(null);
  const customerPrintRef = useRef(null);
  const pharmacistPrintRef = useRef(null);

  const { process: queueProcess, orderId }: Readonly<Params<string>> = useParams();
  // const process = 'create-ticket';
  // const orderId = '00011';

  if (queueProcess === ScriptProcess.CollectTicket) {
    if (!orderId) navigate('/queue/tickets');
  }

  const currentOrder: QueueElement = orders
    ? orders.find(order => order.id === parseInt(orderId!)) || {} as QueueElement
    : {} as QueueElement;

  // state
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [phone, setPhone] = useState('');
  const [notesInput, setNotesInput] = useState('');
  const [notes, setNotes] = useState('');
  const [ticketItems, setTicketItems] = useState([] as Array<any>);
  const [requiresCounselling, setRequiresCounselling] = useState(false);
  const [queueNumber, setQueueNumber] = useState('');
  const [queueNumberId, setQueueNumberId] = useState(0);
  const [queueNumberCounselling, setQueueNumberCounselling] = useState('');
  const [queueNumberIdCounselling, setQueueNumberIdCounselling] = useState(0);
  const [reminderRequired, setReminderRequired] = useState(true);

  const [confirmIdMethod, setConfirmIdMethod] = useState('no-id-required');
  const [confirmCounselling, setConfirmCounselling] = useState('yes');
  const [confirmConsult, setConfirmConsult] = useState('yes');

  const [isLoading, setIsLoading] = useState(false);
  const [prescriptionIsLoading, setPrescriptionIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPaperScriptsMessage, setShowPaperScriptsMessage] = useState(false);
  const [queueNumberErrorMessage, setQueueNumberErrorMessage] = useState('');
  const [paperScriptNotesEnabled, setPaperScriptNotesEnabled] = useState(true);
  const [paperScriptNote, setPaperScriptNote] = useState('');
  const [currentItemId, setCurrentItemId] = useState(0);


  // confirm user is valid
  useEffect(() => {
    apiHelper.getCurrentUser()
      .then(response => {
        if (response.status !== 200) {
          onLogout();
          navigate('/logout/auto');
        }
      })
      .catch(error => {
        onLogout();
        navigate('/logout/auto');
      })
  }, [])
  
  // # Create the request payload for a new Queue number
  const createNewQueueNumberRequest = (counselling_required: boolean): CreateQueueNumberRequest => {
    // Determine the type of queue number required
    const type = (() => {
      switch(counselling_required) {
        case true: return QueueTypes.Consultation;
        case false: return QueueTypes.Default;
        default: return QueueTypes.Default;
      }
    })();

    // Build the request
    let request: CreateQueueNumberRequest = {
      type,
      location_id: location!.id
    };
    return request;
  }

  // scroll to top when step changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex])

  // methods
  const handleBack = (): void => {
    setErrorMessage('');
    const prevStep = currentStepIndex - 1;
    if (prevStep >= 0) setCurrentStepIndex(prevStep);
  }

  const handleNext = (numSteps: number = 1): void => {
    setErrorMessage('');
    const nextStep = currentStepIndex + numSteps;
    if (nextStep < scriptProcessSteps[queueProcess as keyof typeof scriptProcessSteps].length) setCurrentStepIndex(nextStep);
  }

  const handleClickNext = () => {
    handleNext();
  }

  // # Navigate to the next step, and create a new Queue number
  //
  // This is triggered when clicking `Next` on the **1. Script Info** step.
  const handleClickNextWithQueueNumber = () => {
    // Build the request, with counselling not required
    let request: CreateQueueNumberRequest = createNewQueueNumberRequest(false);

    // Update the state
    setRequiresCounselling(false);
    setQueueNumberErrorMessage('');
    setIsLoading(true);

    // Create a new Queue number and update the state
    api.createQueueNumber(request)
      .then(response => {
        const data = response.data.data;

        // Handle success
        setQueueNumber(data.number);
        setQueueNumberId(data.id);
        setIsLoading(false);
        handleNext();
      })
      .catch(error => {
        console.error(error)

        // Handle error
        setIsLoading(false);
        setQueueNumberErrorMessage('Error generating a new queue number.');
      });
  }
  
  // # Recreate a Queue number
  //
  // This is triggered when changing a Queue type in the **3. Confirmation** step, using the "Pharmacist Counselling Required" radio button.
  const handleRecreateQueueNumber = (counselling_required: boolean) => {
    // Generate queue number if one does not already exist of the correct type
    if (
      !counselling_required && queueNumber === '' ||
      counselling_required && queueNumberCounselling === ''
    ) {
      // Build the request
      let request: CreateQueueNumberRequest = createNewQueueNumberRequest(counselling_required);

      // Update the state
      setRequiresCounselling(counselling_required);
      setErrorMessage('');
      setIsLoading(true);

      // Create a new Queue number and update the state
      api.createQueueNumber(request)
        .then(response => {
          const data = response.data.data;

          // Update the state for a success
          if (counselling_required) {
            setQueueNumberCounselling(data.number);
            setQueueNumberIdCounselling(data.id);
          } else {
            setQueueNumber(data.number);
            setQueueNumberId(data.id);
          }
          
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error)

          // Update the state for a failure
          setIsLoading(false);
          setErrorMessage('Error generating a new queue number.');
        });
    } else {
      // else simply update state
      setRequiresCounselling(counselling_required);
    }
  }

  const handleClickBack = () => {
    handleBack();
  }

  const handleNavToQueue = (category?: string) => {
    handleStepReset();
    navigate(category
      ? `/queue/tickets/${category}`
      : '/queue/tickets'
    );
  }

  const handleStepReset = (): void => {
    setCurrentStepIndex(0);
  }

  const handleKeypadInput = (input: string): void => {
    if (input === 'DEL') {
      setPhone(phone => phone.substring(0, phone.length - 1));
    } else {
      setPhone(phone => phone + input);
    }
    focusPhoneInput();
  }

  const focusPhoneInput = (): void => {
    const input = document.getElementById("phone");
    if (input) input.focus();
  }

  const handleAddTicketItem = (type: QueueItemTypes): void => {
    setErrorMessage('');

    const newItems = {
      id: currentItemId,
      type,
      token: '',
      prescription: null,
      substitutionEnabled: true,
      noteEnabled: true,
      note: ''
    }

    const mutatedArray = JSON.parse(JSON.stringify(ticketItems));

    // remove previous unfinished script
    // if (mutatedArray.length > 0 && mutatedArray[mutatedArray.length - 1].prescription === null) {
    //   mutatedArray.pop();
    // }
    // add new empty script
    mutatedArray.unshift(newItems);
    setTicketItems(mutatedArray);

    setCurrentItemId(v => v + 1);

    setTimeout(() => {
      const input = document.getElementById(`itemInput-${currentItemId}`) || document.getElementById(`notesInput-${currentItemId}`);
      console.log(input);
      if (input !== null) input.focus();
    }, 50);
  }

  const handleSetScripts = (id: number, payload: any): void => {
    const mutatedScripts = JSON.parse(JSON.stringify(ticketItems));
    mutatedScripts.forEach((script: any, i: number) => {
      if (script.id === id) {
        if (payload.hasOwnProperty('token')) script.token = payload.token;
        if (payload.hasOwnProperty('substitutionEnabled')) script.substitutionEnabled = payload.substitutionEnabled;
        if (payload.hasOwnProperty('noteEnabled')) script.noteEnabled = payload.noteEnabled;
        if (payload.hasOwnProperty('note')) script.note = payload.note;
      }
    });
    setTicketItems(mutatedScripts);
  }
  
  const handleSetScriptNote = (id: number, note: any): void => {
    const mutatedScripts = JSON.parse(JSON.stringify(ticketItems));
    mutatedScripts.forEach((script: any, i: number) => {
      if (script.id === id) script.note = note;
    });
    setTicketItems(mutatedScripts);
  }

  const handleSetTokenInput = (scriptId: number, token: string): void => {
    handleSetScripts(scriptId, { token });

    // automatically submit after 18 characters are entered
    if (token.length === 18) {
      handleGetPrescription(scriptId, token);
    }
  }

  const handleDeleteScript = (id: number): void => {
    const mutatedScripts = JSON.parse(JSON.stringify(ticketItems));
    const index = mutatedScripts.findIndex((script: any) => script.id === id);
    if (index !== -1) {
      mutatedScripts.splice(index, 1);
      setTicketItems(mutatedScripts);
    }
  }

  const handleGetPrescription = async (id: number, token: string): Promise<void> => {
    console.log('handleGetPrescription', id, token)
    setPrescriptionIsLoading(true);
    await grecaptcha.enterprise.ready(async () => {
      const recaptcha = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'get_products' });
      handleGetPrescriptionData(id, token, recaptcha);
    });
  }

  const parseMkRaw = (mkRaw: string) => {
    let items: Array<any> = [];
    let mkRawParsed: any = null;
    let errors = [];
    let messages = [];
  
    // parse mk raw json
    try {
      mkRawParsed = JSON.parse(mkRaw);
    } catch (error) {
      messages.push('Error parsing MK Raw JSON');
      errors.push(error);
    }
    
    // extract data
    if (mkRawParsed) {
      // try to parse drug name/s
      try {
        const medicationRequests = searchMkRecursive(mkRawParsed, 'MedicationRequest');
        const hasMedReqDispensed = searchMkRecursive(mkRawParsed, 'medReqDispensed').length !== 0;

        const filteredRequests = medicationRequests.filter((resource: any) => {
          if (hasMedReqDispensed) {
            // use medReqDispensed request if available
            return resource.id === 'medReqDispensed';
          } else {
            // else use resource with meta object
            return Object.hasOwn(resource, 'extension') && 
              resource.meta && 
              resource.meta.profile &&
              resource.meta.profile.includes('http://fhir.medicationknowledge.com.au/StructureDefinition/mk-open-medicationrequest')
          }
        });

        filteredRequests.forEach((object: any) => {
          const genericName = object.extension.find((ext: any) => ext.url === 'http://hl7.org.au/fhir/StructureDefinition/medication-generic-name');
          const brandName = object.extension.find((ext: any) => ext.url === 'http://hl7.org.au/fhir/StructureDefinition/medication-brand-name');
          
          let drug_name = '';
          let repeats = null;
          let prescription_patient_name: string | null = null;

          // parse repeats and patient name if not singular
          if (filteredRequests.length > 1) {
            // try to parse repeats
            try {
              repeats = object.dispenseRequest.numberOfRepeatsAllowed;
            } catch (error) {
              messages.push('Unable to extract repeat data');
              errors.push(error);
            }

            // try to parse patient name
            try {
              const patientResource = object.contained.find((resource: any) => resource.resourceType === 'Patient');
              if (patientResource !== undefined && patientResource.name !== undefined) {
                prescription_patient_name = patientResource.name[0].text;
              }
            } catch (error) {
              messages.push('Unable to extract patient name');
              errors.push(error);
            }
          }

          if (genericName && brandName) {
            drug_name = `${toProperCase(brandName.valueString)} (${toProperCase(genericName.valueString)})`;
          } else if (genericName) {
            drug_name = `${toProperCase(genericName.valueString)}`;
          } else if (brandName) {
            drug_name = `${toProperCase(brandName.valueString)}`;
          }

          if (drug_name !== '') {
            items.push({ 
              drug_name,
              prescription_patient_name,
              repeats
            })
          }
        });
      } catch (error) {
        messages.push('Unable to extract medication name/s');
        errors.push(error);
      }
    }

    // try previous name/supply parsing if necessary
    items.forEach((item: any) => {
      if (item.prescription_patient_name === null || item.prescription_patient_name === '') {
        try {
          const patientResource = mkRawParsed.entry[0].resource.contained.find((resource: any) => resource.resourceType === 'Patient');
          if (patientResource !== undefined && patientResource.name !== undefined) {
            item.prescription_patient_name = patientResource.name[0].text;
          }
        } catch (error) {
          messages.push('Unable to extract patient name (fallback)');
          errors.push(error);
        }
      }

      if (item.repeats === null) {
        try {
          item.repeats = mkRawParsed.entry[0].resource.dispenseRequest.numberOfRepeatsAllowed; 
        } catch (error) {
          messages.push('Unable to extract repeat data (fallback)');
          errors.push(error);
        }
      }
    })

    // log errors
    if (errors.length !== 0) {
      let fullErrorMessage = '';
      console.error('Unable to extract all data. See error message/s below:');
      for (let i = 0; i < errors.length; i++) {
        console.error(messages[i], errors[i]);
        fullErrorMessage += `<code>Parsing error: ${messages[i]} [${errors[i]}]<code><br></br>`;
      }

      // send as email
      fullErrorMessage += `<code>MK Raw payload:<br></br>${mkRaw}<code>`;
      handleSendParsingErrorNotification(fullErrorMessage);
    }

    // return items
    return items;
  }

  const handleSendParsingErrorNotification = async (notificationText: string): Promise<any> => {
    await grecaptcha.enterprise.ready(async () => {
      const recaptcha = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'contact' });
      sendParsingErrorNotification(notificationText, recaptcha);
    });
  }

  const sendParsingErrorNotification = async (notificationText: string, recaptcha: string): Promise<any> => {
    // prepare request body
    const requestBody: ProcessNotificationNewRequest = {
      security_token: recaptcha,
      notification_type_code: NotificationTypes.Email,
      partner: {
        id: user!.partner_id
      },
      contact: {
        first_name: '',
        last_name: '',
        email: 'dev@rivalsoftware.com.au'
      },
      email_type_code: 'support',
      subject: `SparrowHub Queue MK Parsing Error Notification`,
      message: `<code>${notificationText}<code>`
    }

    api.publicProcessNewNotification(requestBody).then(async (response) => {
      const data = JSON.parse((response.data as any).data);
    })
      .catch(error => {
        console.error(error);
      })
  }

  const handleGetPrescriptionData = async (id: number, token: string, recaptcha: string): Promise<void> => {
    // get eScript data from the ScriptServe API
    const requestBody: ProductRequest = {
      security_token: recaptcha,
      prescription_token_number: token,
      // TEMP: use partner 4 for all ScriptServe requests
      partner: { id: 4 }
      // partner: { id: user!.partner_id }
    }
    setErrorMessage('');
    api.getEscriptProducts(requestBody)
      .then(response => {
        const prescriptionItems = parseMkRaw((response.data as any).medication_knowledge_raw!);

        updateScriptData(prescriptionItems, id, token);
      })
      .catch(error => {
        let data = null;
        try {
          if (typeof error.response.data === 'string') {
            data = JSON.parse(error.response.data);
          } else {
            data = error.response.data;
          }
        } catch (error) {
          data = null;
          console.error('Unable to parse error data', error);
        }

        // if we receive a NoResults error, get the MK data
        let prescriptionItems: Array<any> = [];
        if (data !== null && data.error_code === ErrorCode.NoResults) {
          prescriptionItems = parseMkRaw(data.original);
        }

        // check if MK drugs were found
        if (prescriptionItems !== null && Array.isArray(prescriptionItems) && prescriptionItems.length > 0) {
          updateScriptData(prescriptionItems, id, token);
        } else {
          // else show a specific error message based on code
          switch (data.error_code) {
            case ErrorCode.NoResults:
              // account for when there are no results, AND the prescription has been dispensed
              try {
                if (JSON.stringify(data).includes('This prescription has been expired')) {
                  setErrorMessage('This prescription has been expired.');
                  setShowPaperScriptsMessage(false);
                  break;
                }
              } catch (error) {
                console.error("Unexpected response format.")
              }
              
              setErrorMessage('An error occurred while trying to process your request.');
              setShowPaperScriptsMessage(true);
              break;
            case ErrorCode.AlreadyDispensed:
              setErrorMessage('This medication has already been dispensed.');
              setShowPaperScriptsMessage(false);
              break;
            case ErrorCode.NotFound:
              setErrorMessage('The e-script information is unavailable.');
              setShowPaperScriptsMessage(true);
              break;
            case ErrorCode.BadToken:
              setErrorMessage('An invalid token was submitted.');
              setShowPaperScriptsMessage(false);
              break;
            case ErrorCode.MedicationKnowledge:
              setErrorMessage('This service is temporarily unavailable.');
              setShowPaperScriptsMessage(true);
              break;
            case ErrorCode.UnexpectedError:
            default:
              setErrorMessage('An error occurred while trying to process your request.');
              setShowPaperScriptsMessage(true);
              break;
          }
          console.error(error);
          setPrescriptionIsLoading(false);
        }
      })
  }

  const updateScriptData = (prescriptionItems: Array<any>, id: number, token: string): void => {
    let type = 'erxScript';
    let hasUpdated = false;
    let mutatedScripts = JSON.parse(JSON.stringify(ticketItems));

    prescriptionItems.forEach((item: any) => {
      let scriptToUpdate = mutatedScripts.find((script: any) => script.id === id);

      if (scriptToUpdate && !hasUpdated) {
        type = scriptToUpdate.type;
        scriptToUpdate.prescription = item;
        scriptToUpdate.token = token;
        hasUpdated = true;
      } else {
        mutatedScripts.push({
          id: ticketItems.length,
          type,
          token,
          prescription: item,
          substitutionEnabled: true,
          noteEnabled: false,
          note: ''
        })
      }
    });

    setTicketItems(mutatedScripts);
    setPrescriptionIsLoading(false);
  }

  // convert a string of words to Proper/Title case
  const toProperCase = (str: string): string => {
    return str.replace(
      /\w\S*/g,
      (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }
    );
  }

  const focusEscript = (index: number): void => {
    const mutatedScripts = JSON.parse(JSON.stringify(ticketItems));
    mutatedScripts.forEach((script: any, i: number) => {
      if (i === index) {
        script.focus = true;
      } else {
        script.focus = false;
      }
    });
    setTicketItems(mutatedScripts);
    setTimeout(() => {
      const input = document.getElementById(`escript-${index}`);
      input!.focus();
    }, 50);
  }

  const formatQueueItemData = (): Array<QueueItem> => {
    let items: Array<any> = [];

    // escripts and erx paper scripts
    ticketItems.filter(item => item.prescription !== null || [QueueItemTypes.Escript, QueueItemTypes.ErxPaperScript].includes(item.type) === false).forEach(item => {
      let newItem: any = {
        // common fields
        queue_item_type_code: item.type,
        qty: 1,
        queue_item_note: item.note,
        // required fields
        brand_substitution_permitted: item.substitutionEnabled,
        requires_consultation: false
      }

      if (item.prescription !== null) {
        // electronic script-only fields
        newItem.prescription_token = item.token,
        newItem.prescription_patient_name = item.prescription.prescription_patient_name;
        newItem.prescription_name = item.prescription.drug_name;
        newItem.prescription_repeats = item.prescription.repeats ? item.prescription.repeats.toString() : null;
      }

      items.push(newItem);
    });

    return items as Array<QueueItem>;
  }

  const addLocalQueueElement = (newElement: QueueElement): void => {
    const mutatedOrders: Array<QueueElement> = JSON.parse(JSON.stringify(orders));
    mutatedOrders.push(newElement);
    setOrders(mutatedOrders);
  }

  const updateLocalQueueElement = (updatedElement: QueueElement): void => {
    const mutatedOrders: Array<QueueElement> = JSON.parse(JSON.stringify(orders));
    const index = mutatedOrders.findIndex(el => el.id === updatedElement.id);
    // only update if order is represented locally
    if (index !== -1) {
      mutatedOrders[index] = updatedElement;
      setOrders(mutatedOrders);
    }
  }

  const successDuration = 3000;
  const handleCompleteProcess = async (): Promise<any> => {
    setIsLoading(true);
    if (queueProcess === ScriptProcess.CreateTicket) {
      return new Promise<any>((resolve, reject) => {
        // reminder notifications currently only enabled for Cat & Claudia's
        let reminder_required = reminderRequired && user!.partner_id === 4;

        // Get the ID of the Queue number if one was created
        let queue_number_id = queueNumberId === 0 ? undefined : requiresCounselling ? queueNumberIdCounselling : queueNumberId;

        // construct request body
        const requestBody: CreateQueueElementRequest = {
          customer_first_name: getCustomerName(),
          customer_phone: phone,
          // customer_email?: string,
          requires_consultation: requiresCounselling,
          user_id: user!.id,
          partner_id: user!.partner_id,
          location_id: location!.id,
          queue_note: notes,
          queue_items: formatQueueItemData(),
          queue_number_id,
          reminder_required
        }

        setErrorMessage('');
        setShowPaperScriptsMessage(false);
        
        console.log('createQueueElement requestBody:');
        console.log(requestBody);

        api.createQueueElement(requestBody)
          .then(response => {
            const data = response.data.data;

            // add new order locally
            addLocalQueueElement(data);

            // send notification if phone provided
            if (data.customer_phone) {
              handleSendNotification(data);
            }

            // nav back to list view
            setIsLoading(false);
            navigate('/queue/tickets');
          })
          .catch(error => {
            setIsLoading(false);
            console.error(error);
            setErrorMessage('Error creating new ticket.');
          })
      })
    } else if (queueProcess === ScriptProcess.CollectTicket) {
      if (currentOrder.requires_consultation) {
        setErrorMessage('');
        setShowPaperScriptsMessage(false);
        await api.processQueueElementCompleteConsultation(currentOrder.id, { consultation_user_id: user!.id });
      }

      api.updateQueueStatus([{ id: currentOrder.id, status_code: QueueStatuses.Collected }])
        .then(response => {
          // Get the first element of the array, since this is for a single update
          const updatedElement: QueueElement = response.data.data[0];

          // add new order locally
          updateLocalQueueElement(updatedElement);

          // nav back to list view
          setIsLoading(false);
          navigate('/queue/tickets/awaiting-collection');
        })
        .catch(error => {
          console.error(error);
          setErrorMessage('Error completing customer collection.');
        })
    }
  }

  const handleSendNotification = async (queueElement: QueueElement): Promise<any> => {
    await grecaptcha.enterprise.ready(async () => {
      const recaptcha = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'contact' });
      sendNotification(queueElement, recaptcha);
    });
  }

  const sendNotification = async (queueElement: QueueElement, recaptcha: string): Promise<any> => {
    let notificationText = `Your ticket (${queueElement.queue_element_number}) is in the queue. You will receive another message when it is ready for collection.\n\nRegards, ${location!.name}`;
    const requestBody: ProcessNotificationNewRequest = {
      notification_type_code: NotificationTypes.Sms,
      security_token: recaptcha,
      message: notificationText,
      partner: {
        id: queueElement.partner_id
      },
      contact: {
        first_name: queueElement.customer_first_name || '',
        last_name: queueElement.customer_last_name || '',
        email: queueElement.customer_email,
        phone: queueElement.customer_phone
      },
      queue_id: queueElement.id,
    }

    setErrorMessage('');
    setShowPaperScriptsMessage(false);
    api.publicProcessNewNotification(requestBody)
      .then(response => {
        handleSetCustomerNotified(queueElement);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('Error sending customer notification.');
      })
  }

  const handleSetCustomerNotified = (queueElement: QueueElement): void => {
    const requestBody: UpdateQueueElementRequest = {
      customer_notified_at: getCurrentTimestamp()
    }

    setErrorMessage('');
    setShowPaperScriptsMessage(false);
    api.updateQueueElement(queueElement.id, requestBody)
      .then(response => {
        const data = JSON.parse((response as any).data.data);
        updateLocalQueueElement(data);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('Error recording customer notification.');
      })
  }
  
  const handlePrintAllDockets = useReactToPrint({
    content: () => docketsPrintRef.current,
    bodyClass: "printable_visible",
  });

  const handlePrintCustomerDocket = useReactToPrint({
    content: () => customerPrintRef.current,
    bodyClass: "printable_visible",
  });

  const handlePrintPharmacistDocket = useReactToPrint({
    content: () => pharmacistPrintRef.current,
    bodyClass: "printable_visible",
  });

  // computed properties
  const steps = (): Array<ScriptProcessStep> => {
    return scriptProcessSteps[queueProcess as keyof typeof scriptProcessSteps]
  }

  const currentStep = (): ScriptProcessStep => {
    return steps()[currentStepIndex]
  }

  const getCustomerName = (): string => {
    if (customerName.trim() !== '') {
      return customerName.trim();
    } else if (ticketItems.length !== 0) {
      const script = ticketItems[0].prescription;
      if (script && script.prescription_patient_name) {
        return script.prescription_patient_name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  const newOrder = (): any => {
    return {
      created_at: getCurrentTimestamp(),
      queue_element_number: requiresCounselling ? queueNumberCounselling : queueNumber,
      customer_first_name: getCustomerName(),
      customer_phone: phone,
      escripts: escripts().filter(script => script.prescription !== null),
      erxScripts: erxScripts().filter(script => script.prescription !== null),
      paperScripts: paperScripts(),
      partner_id: user!.partner_id,
      requires_consultation: requiresCounselling,
      queue_items: formatQueueItemData(),
      queue_note: notes,
      paperScriptNote: paperScriptNotesEnabled ? paperScriptNote : null
    }
  }

  const escripts = (): Array<any> => {
    return ticketItems.filter(script => script.type === QueueItemTypes.Escript);
  }

  const erxScripts = (): Array<any> => {
    return ticketItems.filter(script => script.type === QueueItemTypes.ErxPaperScript);
  }
  
  const paperScripts = (): Array<any> => {
    return ticketItems.filter(script => script.type === QueueItemTypes.PaperScript);
  }
  
  const consultItems = (): Array<any> => {
    return ticketItems.filter(item => queueItemOptions.find(option => option.code === item.type).group === QueueItemTypesGroup.Consultation);
  }
  
  const scriptItems = (): Array<any> => {
    return ticketItems.filter(item => queueItemOptions.find(option => option.code === item.type).group === QueueItemTypesGroup.Script);
  }

  const prescriptionDataString = (): any => {
    const prescriptionData = ticketItems.map((script: any) => script.prescription);
    return JSON.stringify(prescriptionData);
  }

  const customStringFieldIsInvalid = (string: string, customString: string): boolean => {
    if (string === 'custom' || string === 'other') {
      return customString.trim() === '';
    } else {
      return string === '';
    }
  }

  // if (queueProcess === ScriptProcess.AddScript && currentStep() === ScriptProcessStep.CustomerInformation) {
  //   setTimeout(() => {
  //     focusPhoneInput();
  //   }, 50);
  // }

  // const newOrderItems = (): any => {
  //   const pScriptData = [];
  //   for (let i = 0; i < numScripts; i++) {
  //     pScriptData.push({
  //       // required fields
  //       id: '0',
  //       created_at: getCurrentTimestamp(),
  //       order_id: newOrder().platform_order_no,
  //       partner_id: user!.partner_id,
  //       // input fields
  //       sku: 'paper-script',
  //     })
  //   }
  //   const eScriptData = [];
  //   for (let i = 0; i < numEscripts; i++) {
  //     eScriptData.push({
  //       // required fields
  //       id: '0',
  //       created_at: getCurrentTimestamp(),
  //       order_id: newOrder().platform_order_no,
  //       partner_id: user!.partner_id,
  //       // input fields
  //       sku: 'e-script',
  //       name: escripts[i].prescription.drug_name,
  //       prescription_first_name: escripts[i].prescription.patient_name,
  //       qty_ordered: escripts[i].prescription.repeats,
  //       prescription_token_number: escripts[i].token
  //     })
  //   }
  //   return JSON.stringify({ pScriptData, eScriptData })
  // }

  return (
    <>
      {!loggedin || !location ?
        <Navigate to="/login" />
        :
        <>
          {!orders ?
            <Navigate to="/send/orders" />
            :
            <Body key={queueProcess} loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
              <StyledScriptProcessPage>
                {queueProcess !== ScriptProcess.CollectTicket &&
                  <div className="ProcessScriptModal_processSteps divider">
                    <ProcessSteps steps={scriptProcessSteps[queueProcess as keyof typeof scriptProcessSteps]} current={currentStepIndex} />
                  </div>
                }

                {currentStep() === ScriptProcessStep.TicketItems &&
                  <>
                    <div className="ProcessScriptModal_stepHeader">
                      <div className="ProcessScriptModal_stepButton secondary" onClick={() => handleNavToQueue()}>
                        <img src={arrowIcon} alt="" draggable="false" />
                        <p>Back</p>
                      </div>
                      <h2>Ticket Items</h2>
                      <div className={`ProcessScriptModal_stepButton primary ${(ticketItems.length === 0 || ticketItems.filter(item => [QueueItemTypes.ErxPaperScript, QueueItemTypes.Escript].includes(item.type) && item.prescription === null).length === ticketItems.length) && 'disabled'}`} onClick={handleClickNextWithQueueNumber}>
                        <p>Next</p>
                        <img src={arrowIcon} alt="" draggable="false" />
                      </div>
                    </div>

                    {queueNumberErrorMessage &&
                      <div style={{ marginBottom: '18px', marginTop: '-2px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{queueNumberErrorMessage}<br />Please try again by clicking <span className="bold">Next</span> or <DefaultErrorMessage text="contact SparrowHub" /></p>
                        </Alert>
                      </div>
                    }

                    <div className="ProcessScriptModal_items">
                      <aside className="ProcessScriptModal_addItems">
                        <div className="ProcessScriptModal_itemContainer scripts">
                          <p className="largeText bold">Scripts</p>
                          {queueItemOptions.filter(type => type.group === QueueItemTypesGroup.Script).map((type: any) => {
                            return (
                              <button onClick={() => handleAddTicketItem(type.code)} key={type.code}>{type.label}</button>
                            )
                          })}
                        </div>
                        <div className="ProcessScriptModal_itemContainer consults">
                          <p className="largeText bold">Consultations</p>
                          {queueItemOptions.filter(type => type.group === QueueItemTypesGroup.Consultation).map((type: any) => {
                            return (
                              <button onClick={() => handleAddTicketItem(type.code)} key={type.code}>{type.label}</button>
                            )
                          })}
                        </div>
                      </aside>
                      
                      <div className="ProcessScriptModal_scriptSection">
                        {ticketItems.length === 0 ?
                          // empty ticket placeholder
                          <div className="ProcessScriptModal_defaultContainer emptyTicket">
                            <p className="italic">No scripts or consultations have been added</p>
                          </div>
                        :
                          // ticket item list
                          <>
                            {ticketItems.map((item: any) => {
                              return (
                                <div className="ProcessScriptModal_defaultContainer ticketItem" key={`item-${item.id}`}>
                                  {/* Render escript and erx_paper_script items */}
                                  {(item.type === QueueItemTypes.Escript || item.type === QueueItemTypes.ErxPaperScript) ?
                                    <>
                                      {item.prescription !== null ?
                                        <ScriptInformation script={item} key={`script-block-${item.id}`} handleChange={handleSetScripts} handleDelete={handleDeleteScript} />
                                      :
                                        <div className="ProcessScriptModal_tokenInputContainer">
                                          <h5 className="heading5">{queueItemOptions.find(option => option.code === item.type).label}</h5>
                                          <button className="deleteButton buttonShadow" onClick={() => { handleDeleteScript(item.id); setErrorMessage(''); setShowPaperScriptsMessage(false); }}>
                                            <img src={deleteIcon} alt="Delete item" />
                                          </button>
                                          <div className="ProcessScriptModal_escriptContainer">
                                            <InputField type="text" id={`itemInput-${item.id}`} autoComplete="off" placeholder="Enter 18-digit token or scan QR code" uppercase value={item.token} onChange={(e: ChangeEvent) => handleSetTokenInput(item.id, (e.target as HTMLInputElement).value)} />
                                          </div>
                                          <div className={`buttonContainer ${prescriptionIsLoading && 'hidden'}`}>
                                            {errorMessage &&
                                              <div style={{ marginTop: '18px' }}>
                                                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                                                  <p>
                                                    {errorMessage}<br />
                                                    <DefaultErrorMessage />
                                                    {showPaperScriptsMessage &&
                                                      <><br />Alternatively, add this item as a <strong>Paper Script</strong> to proceed.</>
                                                    }
                                                  </p>
                                                </Alert>
                                              </div>
                                            }
                                          </div>
                                          <div className={`loader ${!prescriptionIsLoading && 'hidden'}`}>
                                            <img src={sparrowhubLoader} alt="Loading spinner" draggable="false" />
                                            <p>Retrieving prescription from exchange service...</p>
                                          </div>
                                        </div>
                                      }
                                    </>
                                  :
                                    <>
                                      <h5 className="heading5">{queueItemOptions.find(option => option.code === item.type).label}</h5>
                                      <TextArea id={`notesInput-${item.id}`} small grey placeholder={`Add ${queueItemOptions.find(option => option.code === item.type).group} notes here...`} value={item.note} maxLength={512} onChange={(e: ChangeEvent) => { handleSetScriptNote(item.id, (e.target as HTMLTextAreaElement).value) }} />
                                      <button className="deleteButton buttonShadow" onClick={() => { handleDeleteScript(item.id); setErrorMessage(''); setShowPaperScriptsMessage(false); }}>
                                        <img src={deleteIcon} alt="Delete item" />
                                      </button>
                                    </>
                                  }
                                </div>
                              )
                            })}
                          </>
                        }
                      </div>

                      {/* <div className="ProcessScriptModal_scriptSection">
                        <h3>E-Scripts</h3>
                        <>
                          {escripts().map((script: any) => {
                            return (
                              <div className="ProcessScriptModal_defaultContainer scriptBlock" key={`escript-${script.id}`}>
                                {script.prescription !== null ?
                                  <ScriptInformation script={script} key={`script-block-${script.id}`} handleChange={handleSetScripts} handleDelete={handleDeleteScript} />
                                  :
                                  <div className="ProcessScriptModal_tokenInputContainer">
                                    <div className="ProcessScriptModal_escriptContainer">
                                      <InputField type="text" id={`escript-${script.id}`} autoComplete="off" placeholder="Enter 18-digit token or scan QR code" uppercase value={script.token} onChange={(e: ChangeEvent) => handleSetTokenInput(script.id, (e.target as HTMLInputElement).value)} />
                                    </div>
                                    <div className={`buttonContainer ${prescriptionIsLoading && 'hidden'}`}>
                                      <Button text="DELETE" type={ButtonType.Primary} colour={ButtonColour.RedAlt} icon={ButtonIcon.Trash} small reverse onClick={() => { handleDeleteScript(script.id); setErrorMessage(''); setShowPaperScriptsMessage(false); }} loading={prescriptionIsLoading} />
                                      {errorMessage &&
                                        <div style={{ marginTop: '18px' }}>
                                          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                                            <p>
                                              {errorMessage}<br />
                                              <DefaultErrorMessage />
                                              {showPaperScriptsMessage &&
                                                <><br />Alternatively, add the script under <strong>Paper Scripts</strong> below to proceed.</>
                                              }
                                            </p>
                                          </Alert>
                                        </div>
                                      }
                                    </div>
                                    <div className={`loader ${!prescriptionIsLoading && 'hidden'}`}>
                                      <img src={sparrowhubLoader} alt="Loading spinner" draggable="false" />
                                      <p>Retrieving prescription from exchange service...</p>
                                    </div>
                                  </div>
                                }
                              </div>
                            )
                          })}
                        </>
                        {(escripts().every(script => script.prescription !== null)) &&
                          <div className="ProcessScriptModal_defaultContainer addScriptButton" onClick={() => handleAddScript('escript')}>
                            <p className="bold copy">Add {escripts().length === 0 ? 'an' : 'another'} e-script</p>
                            <img src={plusIcon} alt="" draggable="false" />
                          </div>
                        }
                      </div> */}

                      {/* <div className="ProcessScriptModal_scriptSection">
                        <h3>eRx Paper Scripts</h3>
                        <div className="ProcessScriptModal_erxWarning">
                          <Alert type={AlertType.Important} icon={AlertIcon.ExclamationRedAlt} small>
                            <p>The physical script must be collected</p>
                          </Alert>
                        </div>
                        <>
                          {erxScripts().map((script: any) => {
                            return (
                              <div className="ProcessScriptModal_defaultContainer scriptBlock" key={`erxScript-${script.id}`}>
                                {script.prescription !== null ?
                                  <ScriptInformation script={script} key={`script-block-${script.id}`} handleChange={handleSetScripts} handleDelete={handleDeleteScript} />
                                  :
                                  <div className="ProcessScriptModal_tokenInputContainer">
                                    <div className="ProcessScriptModal_escriptContainer">
                                      <InputField type="text" id={`escript-${script.id}`} autoComplete="off" placeholder="Enter 18-digit token or scan QR code" uppercase value={script.token} onChange={(e: ChangeEvent) => handleSetTokenInput(script.id, (e.target as HTMLInputElement).value)} />
                                    </div>
                                    <div className={`buttonContainer ${prescriptionIsLoading && 'hidden'}`}>
                                      <Button text="DELETE" type={ButtonType.Primary} colour={ButtonColour.RedAlt} icon={ButtonIcon.Trash} small reverse onClick={() => { handleDeleteScript(script.id); setErrorMessage(''); setShowPaperScriptsMessage(false); }} loading={prescriptionIsLoading} />
                                      {errorMessage &&
                                        <div style={{ marginTop: '18px' }}>
                                          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                                            <p>
                                              {errorMessage}<br />
                                              <DefaultErrorMessage /><br />
                                              {showPaperScriptsMessage &&
                                                <><br />Alternatively, add the script under <strong>Paper Scripts</strong> below to proceed.</>
                                              }
                                            </p>
                                          </Alert>
                                        </div>
                                      }
                                    </div>
                                    <div className={`loader ${!prescriptionIsLoading && 'hidden'}`}>
                                      <img src={sparrowhubLoader} alt="Loading spinner" draggable="false" />
                                      <p>Retrieving prescription from exchange service...</p>
                                    </div>
                                  </div>
                                }
                              </div>
                            )
                          })}
                        </>
                        {(erxScripts().every(script => script.prescription !== null)) &&
                          <div className="ProcessScriptModal_defaultContainer addScriptButton" onClick={() => handleAddScript('erxScript')}>
                            <p className="bold copy">Add {erxScripts().length === 0 ? 'an' : 'another'} eRx script</p>
                            <img src={plusIcon} alt="" draggable="false" />
                          </div>
                        }
                      </div> */}

                      {/* <div className="ProcessScriptModal_scriptSection">
                        <h3>Paper Scripts</h3>
                        <div className="ProcessScriptModal_defaultContainer">
                          <div className="paperScriptQty">
                            <p className="bold copy">How many paper scripts does the patient have?</p>
                            <QuantitySelector label="" quantity={numPaperScripts} min={0} onChange={setNumPaperScripts} large />
                          </div>
                          {numPaperScripts > 0 &&
                            <div className="paperScriptNote">
                              {paperScriptNotesEnabled &&
                                <TextArea id="notes-input" small grey placeholder="Add script notes here..." value={paperScriptNote} maxLength={512} onChange={(e: ChangeEvent) => { setPaperScriptNote((e.target as HTMLTextAreaElement).value) }} />
                              }
                            </div>
                          }
                        </div>
                      </div> */}
                    </div>
                  </>
                }

                {currentStep() === ScriptProcessStep.CustomerInformation &&
                  <>
                    <div className="ProcessScriptModal_stepHeader">
                      <div className="ProcessScriptModal_stepButton secondary" onClick={handleClickBack}>
                        <img src={arrowIcon} alt="" draggable="false" />
                        <p>Back</p>
                      </div>
                      <h2>Customer Info</h2>
                      <div className={`ProcessScriptModal_stepButton primary ${isLoading && 'loading'}`} onClick={handleClickNext}>
                        <p>Next</p>
                        <img src={arrowIcon} alt="" draggable="false" />
                      </div>
                    </div>

                    <div className="ProcessScriptModal_stepContents">
                      <h3>Enter the customer's contact information:</h3>
                      <InputField type="text" id="customerName" label="Name" autoComplete="off" value={customerName} onChange={(e: ChangeEvent) => setCustomerName((e.target as HTMLInputElement).value)} />
                      <InputField type="text" id="phone" label="Mobile Number" autoComplete="off" value={phone} regex={/^[\d+]+$/} onChange={(e: ChangeEvent) => setPhone((e.target as HTMLInputElement).value)} />
                      <KeypadInput onInput={handleKeypadInput} />

                      <Alert type={AlertType.Important}>
                        <p>If no <span className="bold">phone number</span> is entered, the customer will not receive updates about their ticket</p>
                      </Alert>

                      <Checkbox id="reminderOptOut" selected={reminderRequired} onChange={() => setReminderRequired(val => !val)}>
                        <span style={{ cursor: 'pointer' }}>Customer consents to receiving marketing communications from {partner.name}.</span>
                      </Checkbox>
                    </div>
                  </>
                }

                {currentStep() === ScriptProcessStep.Confirmation &&
                  <>
                    <div className="ProcessScriptModal_stepHeader">
                      <div className="ProcessScriptModal_stepButton secondary" onClick={handleClickBack}>
                        <img src={arrowIcon} alt="" draggable="false" />
                        <p>Back</p>
                      </div>
                      <h2>Confirmation</h2>
                      <div className={`ProcessScriptModal_stepButton primary green ${isLoading && 'loading'}`} onClick={handleCompleteProcess}>
                        <p>Complete</p>
                        <img src={arrowIcon} alt="" draggable="false" />
                      </div>
                    </div>

                    <div className="ProcessScriptModal_stepContents">
                      <ScriptDetails order={newOrder()} />

                      <div className="ProcessScriptModal_headingContainer">
                        <h3>Ticket Summary</h3>
                        <Slider label="Mandatory Pharmacist Counselling" state={requiresCounselling} onChange={() => handleRecreateQueueNumber(!requiresCounselling)} reverse smallLabel />
                      </div>

                      {scriptItems().length !== 0 &&
                        <div className="ScriptProcessModal_summaryTable">
                          <div className="row header">
                            <p className="bold">Script Information</p>
                          </div>
                          {scriptItems().filter(script => script.prescription !== null || script.type === QueueItemTypes.PaperScript).map((script: any, i: number) => {
                            return (
                              <div className="row" key={`summaryRow-${i}`}>
                                <div>
                                  <p className="itemType script">
                                    <img src={queueItemOptions.find(option => option.code === script.type).icon} alt="" draggable="false" />
                                    {queueItemOptions.find(option => option.code === script.type).label}
                                  </p>
                                  {script.type === QueueItemTypes.PaperScript ?
                                    <p className="scriptName">Paper Script</p>
                                  :
                                    <p className="scriptName">{script.prescription.drug_name || script.token}</p>
                                  }
                                  {/* <p className="scriptName">{script.type === QueueItemTypes.PaperScript ? 'Paper Script' : script.prescription.drug_name || script.token}</p> */}
                                  {script.note &&
                                    <>
                                      <p className="scriptNote bold">Script Notes</p>
                                      <p className="scriptNote">{script.note}</p>
                                    </>
                                  }
                                </div>
                              </div>
                            )
                          })}
                          {/* {numPaperScripts !== 0 &&
                            <div className="row">
                              <div>
                                <p className="itemType">
                                  <img src={paperScriptIcon} alt="" draggable="false" />
                                  Paper Script
                                </p>
                                <p className="scriptName">Paper Script</p>
                                {(paperScriptNotesEnabled && paperScriptNote !== '') &&
                                  <>
                                    <p className="scriptNote bold">Script Notes</p>
                                    <p className="scriptNote">{paperScriptNote}</p>
                                  </>
                                }
                              </div>
                            </div>
                          } */}
                        </div>
                      }

                      {consultItems().length !== 0 &&
                        <div className="ScriptProcessModal_summaryTable">
                          <div className="row header">
                            <p className="bold">Consultation Information</p>
                          </div>
                          {consultItems().map((item: any, i: number) => {
                            return (
                              <div className="row" key={`summaryRow-${item.id}`}>
                                <div>
                                  <p className="itemType consultation">
                                  <img src={queueItemOptions.find(option => option.code === item.type).icon} alt="" draggable="false" />
                                    {queueItemOptions.find(option => option.code === item.type).label}
                                  </p>
                                  <p className="scriptNote bold">Consultation Notes</p>
                                  {item.note ?
                                    <p className="scriptNote">{item.note}</p>
                                  :
                                    <p className="scriptNote italic">None</p>
                                  }
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      }

                      <div className="ProcessScriptModal_buttonContainer">
                        <Button text="Print All Dockets" type={ButtonType.Primary} colour={ButtonColour.Green} icon={ButtonIcon.Print} small onClick={handlePrintAllDockets} />
                        <Button text="Print Customer Docket" type={ButtonType.Primary} colour={ButtonColour.Green200} icon={ButtonIcon.Print} small onClick={handlePrintCustomerDocket} />
                        <Button text="Print Pharmacist Docket" type={ButtonType.Primary} colour={ButtonColour.Green200} icon={ButtonIcon.Print} small onClick={handlePrintPharmacistDocket} />
                      </div>

                      {errorMessage &&
                        <div>
                          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                            <p>{errorMessage}<br /><DefaultErrorMessage /></p>
                          </Alert>
                        </div>
                      }

                      {/* <Button text="Proceed to Next Step" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={escripts.some((escript: any) => escript.prescription === null)} onClick={handleNext} /> */}
                      {/* <Button text="Create Order" type={ButtonType.Primary} icon={ButtonIcon.Arrow} loading={isLoading} disabled={escripts.some((escript: any) => escript.prescription === null)} onClick={handleCompleteProcess} /> */}
                      {/* <Button text="Back" type={ButtonType.Secondary} onClick={handleBack} /> */}
                    </div>
                    <div className="ProcessScriptModal_stepContents orderNotes" style={{ marginTop: '15px' }}>
                      <h3>Ticket Notes</h3>
                      <TextArea id="notes-input" small value={notes} maxLength={512} onChange={(e: ChangeEvent) => setNotes((e.target as HTMLTextAreaElement).value)} />
                    </div>

                    {/* <Modal show={showNotesModal}>
                      <Heading heading="Add order notes for the dispensing Pharmacist:" />
                      <TextArea id="notes-input" value={notesInput} maxLength={250} onChange={(e: ChangeEvent) => setNotesInput((e.target as HTMLTextAreaElement).value)} />
                      <Button text="Save Changes" type={ButtonType.Primary} disabled={notesInput === notes} onClick={() => { setNotes(notesInput); setShowNotesModal(false) }} />
                      <Button text="Back" type={ButtonType.Secondary} onClick={() => setShowNotesModal(false)} />
                    </Modal> */}

                    <div className="printable_hidden" ref={docketsPrintRef}>
                      <div className="printable_hidden" ref={customerPrintRef}>
                        <ScriptDocket type={ScriptDocketType.Customer} order={newOrder()} config={config} />
                      </div>
                      <div className="printable_hidden" ref={pharmacistPrintRef}>
                        <ScriptDocket type={ScriptDocketType.Pharmacist} order={newOrder()} config={config} />
                      </div>
                    </div>
                  </>
                }

                {currentStep() === ScriptProcessStep.ScriptCollection &&
                  <>
                    <div className="ProcessScriptModal_stepHeader">
                      <div className="ProcessScriptModal_stepButton secondary" onClick={() => handleNavToQueue('awaiting-collection')}>
                        <img src={arrowIcon} alt="" draggable="false" />
                        <p>Back</p>
                      </div>
                      <h2>Collection</h2>
                      <div className={`ProcessScriptModal_stepButton primary green ${confirmCounselling !== 'yes' && 'disabled'} ${isLoading && 'loading'}`} onClick={handleCompleteProcess}>
                        <p>Complete</p>
                        <img src={arrowIcon} alt="" draggable="false" />
                      </div>
                    </div>

                    {currentOrder.requires_consultation &&
                      <div className="ProcessScriptModal_defaultContainer scriptCollection requiresConsultation">
                        <h3>Customer Counselling</h3>
                        <p className="bold">Counselling is required before this ticket can be released.</p>
                        <SelectInput
                          id="confirmCounselling"
                          label="Has this occurred?"
                          options={confirmCounsellingOptions}
                          value={confirmCounselling}
                          onChange={(e: ChangeEvent) => setConfirmCounselling((e.target as HTMLInputElement).value as OrderDeliveryType)}
                        />
                      </div>
                    }
                    
                    {ticketContainsConsults(currentOrder) &&
                      <div className="ProcessScriptModal_defaultContainer scriptCollection">
                        <h3>Consultation</h3>
                        <SelectInput
                          id="confirmConsult"
                          label="Has the consultation occurred?"
                          options={confirmConsultationOptions}
                          value={confirmConsult}
                          onChange={(e: ChangeEvent) => setConfirmConsult((e.target as HTMLInputElement).value)}
                        />
                      </div>
                    }

                      <div className="ProcessScriptModal_defaultContainer scriptCollection">
                        {ticketContainsScripts(currentOrder) &&
                          <>
                            <h3>Customer Identity Check</h3>
                            <SelectInput
                              id="confirmId"
                              label="How have you confirmed the customer's identity?"
                              options={confirmIdOptions}
                              value={confirmIdMethod}
                              onChange={(e: ChangeEvent) => setConfirmIdMethod((e.target as HTMLInputElement).value as OrderDeliveryType)}
                            />
                          </>
                        }
                        <h3>Notes</h3>
                        <TextArea id="notes-input" small value={notes} maxLength={512} onChange={(e: ChangeEvent) => setNotes((e.target as HTMLTextAreaElement).value)} />
                      </div>
                  </>
                }
              </StyledScriptProcessPage>

              <Modal show={showSuccess}>
                <Alert type={AlertType.PositiveSecondary} successModal>
                  {queueProcess === ScriptProcess.CreateTicket &&
                    <p>This ticket has been created and can be found in <span className="extrabold">{config.use_incoming_queue ? 'Incoming Queue' : 'Dispensary Queue'}</span></p>
                  }
                  {queueProcess === ScriptProcess.CollectTicket &&
                    <p>This ticket has successfully been marked as collected</p>
                  }
                </Alert>
              </Modal>
            </Body>
          }
        </>
      }
    </>
  );
}

const StyledScriptProcessPage = styled.div`
  padding-bottom: 50px;

  .ProcessScriptModal_processSteps {
    margin-bottom: 42px;
    z-index: 100;
    position: relative;
  }

  h3 {
    font-size: 1.375rem; // 22px
    font-family: "Mulish ExtraBold";
    font-weight: normal;
  }

  .ProcessScriptModal_stepHeader {
    &:before {
      content: "";
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      mask-image: linear-gradient(black 0%, black 145px, transparent 145px);
      pointer-events: none;
      transition: background-image 0.2s ease-in-out, opacity 0.2s ease;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    
    position: sticky;
    top: 80px;
    z-index: 99;

    h2 {
      font-size: 1.25rem; // 20px
      margin: 0;
    }

    .ProcessScriptModal_stepButton {
      width: 112px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 6px;
      cursor: pointer;
      
      ${boxShadows.default}
      transition:
        box-shadow ${transitions.default},
        transform ${transitions.default},
        opacity ${transitions.default};

      img {
        width: 12px;
        height: auto;
        margin-top: 2px;
      }
      
      p {
        margin: 0;
        font-size: 0.8rem; // 12px
        font-weight: bold;
      }

      &:hover {
        ${boxShadows.hover}
      }

      &.primary {
        background: black;
        color: white;

        img {
          filter: invert();
          transform: rotate(180deg);
        }
      }

      &.green {
        background: #35C680;
      }
      
      &.secondary {
        background: white;
        color: black;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      
      &.loading {
        cursor: wait;
        opacity: 0.5;

        &:active {
          pointer-events: none;
        }
      }
    }
  }

  .ProcessScriptModal_tokenInputContainer {
    position: relative;

    .deleteButton {
      top: -4px !important;
      right: 0 !important;
    }

    h5 {
      margin: 0 0 20px 0;
    }

    .ProcessScriptModal_escriptContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 0;

      .InputField {
        margin: 0;
        width: 100%;

        input {
          margin: 0;
        }
      }

      .Button {
        height: 48px;
        margin: 0;
        width: 100px;
      }

    }

    .Button_RedAlt {
      margin-top: 18px;
    }

    .buttonContainer, .loader {
      transition: opacity ${transitions.default};
      
      &.hidden {
        opacity: 0;
        pointer-events: none;
        height: 0;
      }
    }

    .loader {
      /* position: absolute;
      bottom: 0; */
      width: 100%;
      height: 41px;
      margin-top: 18px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      img {
        height: 60px;
        width: auto;
      }
      
      p {
        margin: 0;
      }
    }
  }

  .ProcessScriptModal_escriptDetails {
    display: flex;
    gap: 18px;
    margin-top: 8px;
    margin-bottom: 20px;

    .qrcode {
      padding: 5px 0;
    }

    .header {
      margin-top: 3px;
      margin-bottom: 5px;
    }

    .details {
      font-size: 0.75rem; // 12px
      margin: 0;
    }
  }
  
  .ProcessScriptModal_stepContents {
    padding-top: 50px;

    ${containers.default}
    padding-top: 36px;
    position: relative;
    overflow: hidden;

    .Heading {
      margin-bottom: 35px;
    }

    .InputField {
      margin-bottom: 20px;
    }

    .Button {
      :not(.Button_addParcel) {
        margin: 25px 0;
      }

      &.Button_addParcel {
        margin-top: 8px;
        margin-bottom: 28px;
        height: 48px;
      }

      &.Button_primary {
        margin-top: 40px;
      }
    }

    .ProcessScriptModal_buttonContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 41px 41px;
      gap: 14px 10px;
      margin-top: 40px;

      button:first-child {
        grid-column: 1 / span 2;
      }

      .Button {
        margin: 0;
      }
    }

    .Alert {
      margin-top: 30px;
    }

    .ProcessScriptModal_detailsContainer {
      padding: 15px 0 20px 0;
      margin-bottom: 10px;
    }
    
    .ProcessScriptModal_headingContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.orderNotes {
      .TextArea {
        margin-top: -10px;
        margin-bottom: 0px;
      }
    }

    .ScriptProcessModal_summaryTable {
      margin-bottom: 50px;

      .header {
        border-bottom: 3px solid black;
        height: 50px !important;
        /* padding: 0 10px !important; */
        padding: 0 !important;
        
        p {
          font-size: 1rem; // 16px
        }
      }

      .row {
        display: grid;
        grid-template-columns: 1fr 66px;
        padding: 8px 10px;
        align-items: center;

        &:nth-child(2n+3) {
          background-color: #F8F8F8;
        }

        p.itemType {
          font-size: 0.5625rem; // 9px
          margin: 4px 0;
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 4px;
          height: 18px;
          padding: 0 8px 0 5px;
          color: white;
          border-radius: 18px;

          img {
            height: 10px;
            width: auto;
            filter: invert();
          }

          &.script {
            background: ${colours.blue300};
          }

          &.consultation {
            background: ${colours.orange300};
            img {
              height: 12px;
              margin-right: -2px;
            }
          }
        }
        
        p.scriptName {
          margin: 4px 0;
        }

        p.scriptNote {
          margin: 0 0 4px 0;
          font-size: 0.75rem; // 12px

          &.bold {
            margin-top: 10px;
          }
        }

        .center {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  .ProcessScriptModal_items {
    position: relative;
    padding-top: 9px;

    .ProcessScriptModal_addItems {
      position: absolute;
      width: 210px;
      left: -241px;
      top: -18px;

      .ProcessScriptModal_itemContainer {
        margin-bottom: 67px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        p {
          color: ${colours.neutral600};
          margin-bottom: -9px;
          margin-top: 0;
        }

        button {
          position: relative;
          width: 100%;
          text-align: left;
          border: none;
          height: 39px;
          border-radius: 6px;
          cursor: pointer;
          transition: box-shadow ${transitions.default}, transform ${transitions.fast};
          padding-left: 53px;
          box-shadow: none;
          
          color: white;
          font-family: "Mulish Bold";
          font-weight: normal;
          font-size: 0.875rem; // 14px

          &:after {
            content: '';
            position: absolute;
            left: 20px;
            width: 17px;
            height: 17px;
            background-size: 17px;
            filter: invert();
            background-image: url(${plusIcon});
            background-repeat: no-repeat;
          }

          &:hover {
            ${boxShadows.hover}
          }

          &:active:not(:disabled) {
            transform: scale(0.95);
          }
        }

        &.scripts {
          button {
            background-color: ${colours.blue300};
          }
        }
        &.consults {
          button {
            background-color: ${colours.orange300};
          }
        }
      }
    }
  }

  .ProcessScriptModal_scriptSection {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      padding: 12px 8px 12px 8px;
      border-bottom: 1px solid #DADADA;
      margin: 0 0 20px 0;
    }

    &:not(:last-child) {
      margin-bottom: 85px;
    }

    .ProcessScriptModal_erxWarning {
      position: absolute;
      top: 8px;
      right: 0;

      .Alert {
        background: #FFEEEE;
        p {
          color: #ED2929;
        }
      }
    }
  }

  .ProcessScriptModal_defaultContainer {
    background: white;
    border-radius: 6px;
    padding: 15px;
    min-height: 100px;
    box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);

    &.ticketItem {
      position: relative;
      padding: 37px 52px;

      h5 {
        margin: 0 0 16px 0;
      }

      .TextArea {
        margin: 0;
      }

      .deleteButton {
        background: #EE2959;
        width: 30px;
        height: 26px;
        border-radius: 6px;
        border: none;
        position: absolute;
        top: 33px;
        right: 52px;

        img {
          filter: brightness(0) invert();
          width: 16px;
          height: 16px;
        }
      }
    }

    &.requiresConsultation {
      background: #FFF9FA;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 10px;
        background: linear-gradient(to left, #FF1E2F, #FF5A99 90%);
      }
    }

    &.emptyTicket {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.scriptCollection {
      padding: 25px 52px 45px 52px;
      margin-bottom: 13px;

      .TextArea {
        margin-top: -10px;
        margin-bottom: 8px;
      }
    }

    p.copy {
      font-size: 0.875rem; // 14px
    }

    &.addScriptButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: box-shadow ${transitions.default};
      margin-top: 13px;

      &:hover {
        ${boxShadows.hover}
      }

      p.copy {
        margin-top: 0;
        margin-bottom: 10px;
      }

      img {
        width: 37px;
        height: auto;
      }
    }

    &.scriptBlock {
      padding: 43px 48px;
    }
  }

  .Modal {
    textarea {
      margin: 30px 0 45px 0;
    }

    .Button_primary {
      margin-bottom: 25px;
    }
  }

  .customField_container {
    height: 121px;
    opacity: 1;
    overflow: hidden;
    transition: height ${transitions.default},
                opacity ${transitions.default},
      margin ${transitions.default};

    &.hidden {
      height: 0;
      opacity: 0;
      pointer-events: none;
      margin-bottom: -25px !important;
    }
  }

  .SelectionGroup_grid {
    margin-bottom: 30px;
  }

  .Checkbox {
    margin-top: 16px;
  }
`